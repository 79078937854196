<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row align="center" justify="center" class="fill-height">
      <v-col cols="8">
        <v-img class="background-image" src="@/assets/login_background.png">
        </v-img>
      </v-col>
      <v-col cols="4" class="fill-height">
        <v-form ref="form" class="fill-height" @submit.prevent="submitEmail($event)">
          <v-container fluid class="fill-height" v-if="!emailSent">
            <v-row align="center" justify="center" class="fill-height">
              <v-col cols="12" align="center" justify="center">
                <v-img width="195" src="@/assets/iql_logo.png"/>
              </v-col>
              <v-col cols="12" class="px-12">
                <v-row>
                  <v-col cols="12" class="px-0">
                    <custom-h2 label="Recuperação de Senha"/>
                  </v-col>
                  <v-col cols="12" class="px-0">
                    <span class="font-weight-medium">Insira seu e-mail</span>
                    <simple-text-field v-model="payload.email" email required/>
                  </v-col>
                  <v-col cols="12" class="px-0">
                    <primary-button is-submit label="Confirmar"/>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" align="end" class="pa-0 text-sm-center mt-16">
                @{{ new Date().getFullYear() }} | Drakkar | Todos os direitos reservados
              </v-col>
            </v-row>
          </v-container>

          <v-container fluid class="fill-height" v-else>
            <v-row align="center" justify="center" class="fill-height">
              <v-col cols="12" align="center" justify="center" class="mb-10">
                <v-img width="195" src="@/assets/iql_logo.png"/>
              </v-col>
              <v-col cols="12" class="px-12">
                <v-row>
                  <v-col cols="12" class="px-0">
                    <custom-h2 label="Recuperação de Senha"/>
                  </v-col>
                  <v-col cols="12" class="px-0">
                    <span class="font-weight-thin">O código de recuperação de senha foi enviado para o seu e-mail.</span>
                    <span class="font-weight-regular"> Confira sua Caixa de entrada ou Spam.</span>
                  </v-col>
                  <v-col cols="12" class="px-0">
                    <primary-button @callAction="$router.push('login')" label="Recebido"/>
                  </v-col>
                  <v-col cols="12" class="px-0">
                    <text-button @callAction="resendEmail()" label="Reenviar Código"/>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" align="end" class="pa-0 text-sm-center mt-16">
                @{{ new Date().getFullYear() }} | Drakkar | Todos os direitos reservados
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginService from "@/domain/login/login.service";
import SimpleTextField from "../../../components/inputs/SimpleTextField";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import TextButton from "../../../components/buttons/TextButton";
import CustomH2 from "../../../components/texts/CustomH2";

export default {
  name: "ResetPassword",
  components: {TextButton, PrimaryButton, SimpleTextField,CustomH2},
  data() {
    return {
      payload: {
        email: "",
        currentUrl: ""
      },
      error: "",
      emailSent: false,
    }
  },
  methods: {
    submitEmail() {
      this.error = "";
      if (!this.$refs.form.validate()) {
        return;
      }

      let loader = this.$loading.show();
      LoginService.resetPassword(this.payload)
          .then((response) => {
            this.emailSent = true;
            loader.hide();
            const {success} = response;
            this.$toasted.show(success, {
              type: "success",
            });
          })
          .catch(({response}) => {
            loader.hide();
            const {error} = response.data;
            this.$toasted.show(error, {
              type: "error",
            });
          });
    },
    resendEmail() {
      this.submitEmail();
    },
  }
};
</script>

<style>
  body {
    overflow: hidden;
  }

  h2 {
    font-size: 30px;
  }

  .background-image {
    max-height: 100vh;
  }
</style>
